'use strict';

angular.module('windmanagerApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('printFindings', {
        url: '/print-findings',
        template: '<findings></findings>',
        data: {
          bodyClass: 'print-findings'
        }
      });
  });
